import React, { Component } from 'react'
import util from '../util'

export default class Basket extends Component {
  render() {
    const { cartItems } = this.props
    return (
      <div className='alert alert-info'>
        {
            cartItems.length === 0 
            ? "Basket is empty"
            : <div>You  have {cartItems.length} in basket.<hr/></div>
        }
        {
            cartItems.length > 0 && 
            <div>
                <ul style={{marginLeft: -25}}>
                    {
                        cartItems.map(x => (
                            <li key={x.id}>
                                <strong>{x.title}</strong>
                                <button
                                    style={{float: 'right'}}
                                    className='btn btn-danger'
                                    onClick={ e => this.props.handleRemoveFromCart(e,x) }
                                >
                                    X
                                </button>
                                <br/>
                                {x.count} x {util.formatCurrency(x.price)}
                            </li>
                        ))
                    }
                </ul> 
                <strong>Sum: 
                    {
                        util.formatCurrency(
                            cartItems.reduce((suma,obj)=>(suma + obj.price * obj.count),0)
                        )
                    }
                </strong>   
            </div>
        }
      </div>
    )
  }
}
